import dynamic from 'next/dynamic'
import SharedServiceInstance from "@/services/shared-service";
import {
    getBanners,
    getCategoryData,
    getDailyEvents,
    getGroup,
    getSliderBanner, getWidgets, getAudioCategory
} from "@/services/home-service";
import {TYPE_SPECIAL_GROUP, NEWS_TYPE, HOMEPAGE_DATA_TYPE} from '@/components/helpers/constant.js';
import Head from "next/head";
import { redirect } from "next/navigation"

var _ = require('lodash');

const TopSlider = dynamic(() => import("@/components/main-menu/top-slider"));
const LatestNews = dynamic(() => import("@/components/main-menu/latest-news"));
const SearchClergiesAppointment = dynamic(() => import("@/components/main-menu/search-clergies-appointment"));
const EventsDailyNotification = dynamic(() => import("@/components/main-menu/events-daily-notification"));
const Notification = dynamic(() => import("@/components/main-menu/notification"));
const Parables = dynamic(() => import("@/components/main-menu/parables"));
const MostViewPost = dynamic(() => import("@/components/main-menu/most-view-post"));
const Banner = dynamic(() => import("@/components/main-menu/banner"));
const Category_12_7 = dynamic(() => import("@/components/main-menu/template/category_12-7"));
const SwiperCustom = dynamic(() => import("@/components/main-menu/template/swipperCards"));
const Documentation = dynamic(() => import("@/components/main-menu/documentation"));
const Dossil = dynamic(() => import("@/components/main-menu/dossil"));
const SearchChurchs = dynamic(() => import("@/components/main-menu/search-churchs"));
const DioceseNews = dynamic(() => import("@/components/main-menu/diocese-news"));
const SaltForLife = dynamic(() => import("@/components/main-menu/salt-for-life"));
const Maps = dynamic(() => import("@/components/main-menu/maps"), { ssr: false });
const Audio = dynamic(() => import("@/components/main-menu/audio"));

export async function getStaticProps() {
    const deaneries = await getGroup({
        type: TYPE_SPECIAL_GROUP.DEANERY,
        sort: {attribute: 'name'}
    });

    let result = {
        deaneries: deaneries || []
    }

    // Get Notifications - Parables - Audio
    let widgets = await getWidgets();
    result.sliderBanners = getSliderBanner(widgets);
    result.banners = getBanners(widgets);
    result.audio = await getAudioCategory()

    // Get News
    const newsObjKey = {
        'logos': NEWS_TYPE.LOGOS.link,
        'focusNew': NEWS_TYPE.FOCUS.link,
        'latestNews': NEWS_TYPE.LATEST.link,
        'mostView': NEWS_TYPE.MOST_VIEW.link,
        'dioceseNews': NEWS_TYPE.DIOCESE.code,
        'vnChurchNews': NEWS_TYPE.CHURCH_VN.code,
        'documents': NEWS_TYPE.MATERIAL_LIST.code,
        'universalNews': NEWS_TYPE.UNIVERSAL.code,
        'dossil': HOMEPAGE_DATA_TYPE.DOSSIL.link,
        'parables': HOMEPAGE_DATA_TYPE.PARABLES.link,
        'saltForLifeNews': NEWS_TYPE.SALT_FOR_LIFE.code,
        'priestlyVocation': NEWS_TYPE.PRIESTLY_VOCATION.code,
        'notifications': HOMEPAGE_DATA_TYPE.NOTIFICATIONS.link,
    };
    const postTypes = ['logos', 'focusNew', 'vnChurchNews', 'universalNews', 'priestlyVocation'];
    const childEventName = ['dioceseNews', 'universalNews', 'vnChurchNews']
    for (const [eventName, propertyName] of Object.entries(newsObjKey)) {
        result[eventName] = await getCategoryData(propertyName, childEventName.includes(eventName) ? {} : {status: 'latest'});
        if (postTypes.includes(eventName)) {
            result[eventName].value = _.filter(result[eventName].value, value => value?.photo);
            result[eventName].value = _.slice(result[eventName].value, 0, 6);
        } else if (eventName === 'latestNews') {
            result[eventName].value = _.slice(result[eventName].value, 0, 5);
        }
    }

    return {props: {...result}, revalidate: 10}
}

export default function Home({
                                 logos,
                                 deaneries,
                                 dioceseNews,
                                 mostView,
                                 universalNews,
                                 latestNews,
                                 focusNew,
                                 saltForLifeNews,
                                 vnChurchNews,
                                 notifications,
                                 parables,
                                 dossil,
                                 documents,
                                 priestlyVocation,
                                 sliderBanners,
                                banners,
                                 audio
                             }) {
    const workspaceSettings = SharedServiceInstance.workingSpaceSettings;
    return (
        <div>
            <Head>
                <title>GIÁO PHẬN PHÚ CƯỜNG</title>
                <meta name="description" content="Chuyên trang truyền thông Giáo Phận Phú Cường"/>
                <meta name="keywords" content="Giáo Phận Phú Cường, giao phan phu cuong"/>
                <meta name="author" content="Giáo Phận Phú Cường"/>
                <meta name="GENERATOR" content="Giáo Phận Phú Cường" />
                {/*Facebook Metadata*/}
                <meta property="og:url" content="https://giaophanphucuong.org/"/>
                <meta property="og:type" content="Website"/>
                <meta property="og:locale" content="vi_VN"/>
                <meta property="og:image"
                      content="https://apis.client.giaophanphucuong.com/storage/images/b6678cfe-5184-4d7b-9d82-f0384fce40cb.png"/>
                <meta property="og:description" content="Chuyên trang truyền thông Giáo Phận Phú Cường"/>
                <meta property="og:title" content="GIÁO PHẬN PHÚ CƯỜNG "/>
                <meta property="og:site_name" content="Giáo Phận Phú Cường"/>
                {/*Google+ Metadata*/}
                <meta itemProp="name" content="GIÁO PHẬN PHÚ CƯỜNG"/>
                <meta itemProp="description" content="Chuyên trang truyền thông Giáo Phận Phú Cường"/>
                <meta itemProp="image"
                      content="https://apis.client.giaophanphucuong.com/storage/images/b6678cfe-5184-4d7b-9d82-f0384fce40cb.png"/>
                <link rel="dns-prefetch" href="https://www.youtube.com/embed/" />
                <link rel="preconnect" href="https://www.youtube.com/embed" />
                <link rel="dns-prefetch" href="https://maps.giaophanphucuong.com/maps" />
                <link rel="preconnect" href="https://maps.giaophanphucuong.com/maps" />
            </Head>
            <TopSlider data={focusNew} banners={sliderBanners}/>
            <LatestNews data={latestNews} number={5}/>
            <SearchClergiesAppointment data={deaneries}/>
            <section className="md:grid md:grid-cols-12">
                <div className="md:col-span-7 py-5 w-full">
                    <Category_12_7 data={logos} categoryCode='loi-chua' />
                </div>
                <div className="md:col-span-5 w-full h-full md:pl-10 py-5">
                    <Notification data={notifications}/>
                </div>
            </section>
            <section className='py-5'>
                <Audio data={audio} />
            </section>
            <section>
                <DioceseNews data={dioceseNews}/>
            </section>
            <section className="md:grid md:grid-cols-12">
                <div className="md:col-span-7 py-5 w-full">
                    <Category_12_7 data={priestlyVocation} categoryCode='on-goi-linh-muc' isSubCate={true}/>
                </div>
                <div className="md:col-span-5 w-full h-full md:pl-10 py-5">
                    <EventsDailyNotification />
                </div>
            </section>
            {/* <section>
                <Parables data={parables}/>
            </section> */}
            <section className="py-5">
                <SwiperCustom data={ dossil } categoryCode={ HOMEPAGE_DATA_TYPE.PARABLES.link } />
            </section>
            <section className="md:grid md:grid-cols-12">
                <div className="md:col-span-7 py-5 w-full">
                    <Category_12_7 data={universalNews} categoryCode='tin-giao-hoi-hoan-vu' isSubCate={true}/>
                </div>
                <div className="md:col-span-5 w-full h-full md:pl-10 py-5">
                    <MostViewPost data={mostView}/>
                </div>
            </section>
            <section>
                <Banner banners={banners}/>
            </section>
            <section className="md:grid md:grid-cols-12">
                <div className="md:col-span-7 py-5 w-full">
                    <Category_12_7 data={vnChurchNews} categoryCode='tin-giao-hoi-viet-nam' isSubCate={true}/>
                </div>
                <div className="md:col-span-5 w-full h-full md:pl-10 py-5">
                    <Documentation data={documents}/>
                </div>
            </section>
            <section>
                <SaltForLife data={saltForLifeNews}/>
            </section>
            {/* <section>
                <Dossil data={dossil}/>
            </section> */}
            <section className="py-5">
                <SwiperCustom data={ dossil } categoryCode={ NEWS_TYPE.DOCUMENT_LIST.code } />
            </section>
            <section>
                <SearchChurchs data={deaneries}/>
            </section>
            <section>
                <Maps sourceMap="https://maps.giaophanphucuong.org?mode=iframe&size=xl" />
            </section>
        </div>
    )
}
